<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>文章列表</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建文章</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="文章名">-->
      <!--                            <a-select-option value="username">文章名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">文章名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="4" :span="20">

          <div v-show="!Category_show" :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-row justify="space-between" type="flex">
              <a-col :span="8">
                <h1>{{Category_name}}</h1>
              </a-col>
              <a-col :span="12" style="text-align:right;margin-bottom:10px">

                <a-button style="margin-right: 10px" type="primary" @click="addArticle(Category_id)" v-show="Category_show">
                  新建文章
                </a-button>
                <!--                <a-button style="margin-right: 10px" type="primary" @click="addCategorychildren(Category_id)">-->
                <!--                  新增下级分类-->
                <!--                </a-button>-->
                <a-button style="margin-right: 10px" type="primary" @click="editCategory(Category_id)">
                  编辑分类
                </a-button>
                <a-button style="margin-right: 0px" type="primary" @click="delCategory(Category_id)">
                  剔除分类
                </a-button>

              </a-col>

            </a-row>

            <a-result title="根目录不允许添加文章" v-if="Category_id">

            </a-result>
            <a-result title="请选择目录"  v-if="Category_id===''">

            </a-result>
            <a-divider></a-divider>
<!--            && Category_jump ===2-->
            <div v-if="Category_id && Category_show2===1 ">

              <a-tabs default-active-key="1" tab-position="left">
                <a-tab-pane key="1" tab="cn" forceRender>
<!--                  {{content_cn}}-->
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="简介配图">
<!--                                      {{content_cn.fileList.length}}-->
                    <a-upload
                        :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"
                        :file-list="content_cn.fileList"
                        class="ant-uploads"
                        list-type="picture-card"
                        @change="handIntroductionleChangecn"
                        @preview="handlePreview"
                    >

                      <div v-if="content_cn.fileList.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                          upload
                        </div>
                      </div>

                    </a-upload>
                    <div class="note">
                     * 图片尺寸:600*378<br/>
                      * 图片大小不超过2M<br/>
                      * 图片类型：jpg或png<br/>
                    </div>
                  </a-form-item>
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="标题">
                    <a-input v-model="content_cn.title"/>
                  </a-form-item>

                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="内容">
                    <a-textarea
                        v-model="content_cn.content"
                        placeholder="Introduction"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </a-form-item>
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="跳转链接">
                    <a-input v-model="content_cn.url"/>
                  </a-form-item>
                </a-tab-pane>
                <a-tab-pane key="2" tab="en" forceRender>
<!--                  {{content_en}}-->
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="简介配图">
<!--                    {{content_en.fileList.length}}-->
                    <a-upload
                        :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"
                        :file-list="content_en.fileList"
                        class="ant-uploads"
                        list-type="picture-card"
                        @change="handIntroductionleChangeen"
                        @preview="handlePreview"
                    >
<!--                      <span v-if="content_en.fileList">-->
                      <div v-if="content_en.fileList.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                          upload
                        </div>
                      </div>
<!--                      </span>-->
                    </a-upload>
                    <div class="note">
                      * 图片尺寸:600*378<br/>
                      * 图片大小不超过2M<br/>
                      * 图片类型：jpg或png<br/>
                    </div>
                  </a-form-item>
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="标题">
                    <a-input v-model="content_en.title"/>
                  </a-form-item>

                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="内容">
                    <a-textarea
                        v-model="content_en.content"
                        placeholder="Introduction"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </a-form-item>
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="跳转链接">
                    <a-input v-model="content_en.url"/>
                  </a-form-item>
                </a-tab-pane>
                <a-tab-pane key="3" tab="fr" forceRender>
<!--                  {{content_fr}}-->
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="简介配图">
<!--                    {{content_fr.fileList.length}}-->
                    <a-upload
                        :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Links'"
                        :file-list="content_fr.fileList"
                        class="ant-uploads"
                        list-type="picture-card"
                        @change="handIntroductionleChangefr"
                        @preview="handlePreview"
                    >

                      <div v-if="content_fr.fileList.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                          upload
                        </div>
                      </div>
                    </a-upload>
                    <div class="note">
                      * 图片尺寸:600*378<br/>
                      * 图片大小不超过2M<br/>
                      * 图片类型：jpg或png<br/>
                    </div>
                  </a-form-item>

                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="标题">
                    <a-input v-model="content_fr.title"/>
                  </a-form-item>

                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="内容">
                    <a-textarea
                        v-model="content_fr.content"
                        placeholder="Introduction"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </a-form-item>
                  <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label="跳转链接">
                    <a-input v-model="content_fr.url"/>
                  </a-form-item>
<!--                  <quill-editor-->

<!--                      ref="myQuillEditor"-->
<!--                      v-model="content_fr"-->
<!--                      :options="editorOption"-->
<!--                      style="height: 450px;width: 100%;margin-bottom: 90px"-->
<!--                      @change="onEditorChange_fr($event)"-->

<!--                  >-->
<!--                  </quill-editor>-->

                </a-tab-pane>
              </a-tabs>

            <a-button style="margin-left: 0px;margin-top: 20px" type="primary" @click="saveCategoryinfo(Category_id)">
              保存分类信息
            </a-button>
            </div>
          </div>

          <div v-show="Category_show" :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-row justify="space-between" type="flex">
              <a-col :span="8">
                <h1>分类名称: {{ Category_name }}</h1>
              </a-col>
              <a-col :span="12" style="text-align:right;margin-bottom:10px">
                <a-button type="primary" :loading="loading" @click="start" style="margin-right: 10px">刷新</a-button>

                <a-button style="margin-right: 10px" type="primary" @click="addArticle(Category_id)">
                  新建文章
                </a-button>
                <!--                <a-button style="margin-right: 10px" type="primary" @click="addCategorychildren(Category_id)">-->
                <!--                  新增下级分类-->
                <!--                </a-button>-->
                <a-button style="margin-right: 10px" type="primary" @click="editCategory(Category_id)">
                  编辑分类
                </a-button>
                <a-button style="margin-right: 0px" type="primary" @click="delCategory(Category_id)">
                  剔除分类
                </a-button>

              </a-col>

            </a-row>
            <!--                                {{data}}-->

            <a-table
                :columns="columns"
                :dataSource="CategoryarticleData"
                :loading="loading"
                :pagination="false"
                bordered

                rowKey="id"
            >
              <template slot="type" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.type === 1" :color="'green'">免费</a-tag>
                <a-tag v-if="index.type === 2" :color="'pink'">VVip可见</a-tag>
                <a-tag v-if="index.type === 3" :color="'purple'">Vip可见</a-tag>
                <a-tag v-if="index.type === 4" :color="'blue'">活动报名</a-tag>
              </template>
              <template slot="is_show" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.is_show === 1" :color="'green'">显示</a-tag>
                <a-tag v-if="index.is_show === 2" :color="'purple'">隐藏</a-tag>
              </template>
              <template slot="language" slot-scope="text, index" style="text-align: center">
                <a-tag v-if="index.language === 'cn'" :color="'green'">Cn</a-tag>
                <a-tag v-if="index.language === 'en'" :color="'purple'">En</a-tag>
                <a-tag v-if="index.language === 'fr'" :color="'blue'">Fr</a-tag>
              </template>
              <template slot="operation" slot-scope="text, index">
                            <span style="margin-right: 5px;">
                              <a @click="editArticlerank(index,Category_id)">修改显示顺序</a>
                            </span>
                <span style="margin-right: 5px;">
                              <a @click="editArticle(index,Category_id)">编辑</a>
                            </span>
                <span>
                              <a @click="deleArticle(index)">剔除</a>
                            </span>
              </template>

            </a-table>
<!--            {{pagination}}-->
            <div style="text-align:right;margin-top:10px">
              <a-pagination v-model="pagination.current" :total="pagination.total" :defaultPageSize="pagination.pageSize" show-less-items @change="onChange"/>
            </div>

          </div>
        </a-col>
        <a-col :pull="20" :span="4">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-button block type="primary" @click="addCategory()">
              新建分类
            </a-button>
            <!--            {{ treeData }}-->
            <a-tree :expandedKeys.sync="expandedKeys" :tree-data="treeData" showLine @select="onContextMenuClick">
            </a-tree>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>

    <a-modal v-model="pop_Categoryadd" title="新增分类" width="65%" @ok="Category_addok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="上级分类">
        <!--        {{ treeData }}-->
        <!--        {{ Category_pid }}-->
        <a-tree-select
            v-model="add_Category.p_id"
            :dropdown-style="{ maxHeight: '600px', overflow: 'auto' }"
            :replaceFields="replaceFields"
            :tree-data="treeData"
            placeholder="Please select"
            allow-clear
            tree-default-expand-all
            style="width: 100%"
            @change="onselectpid"
        >
          <span slot="title" slot-scope="{ key, value }">
            {{ value }}
            {{ is_show }}
          </span>
        </a-tree-select>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="中文分类名称">
        <a-input v-model="add_Category.name.cn"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="英文分类名称">
        <a-input v-model="add_Category.name.en"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="法文分类名称">
        <a-input v-model="add_Category.name.fr"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="目录排序"  help="默认 -1  最大999">
        <a-input v-model="add_Category.rank"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="文章封面图">

        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Categoryimages'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否显示">
        <a-radio-group v-model="add_Category.is_show" button-style="solid" @change="Changeis_show">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
           否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否为视频列表">
        <a-radio-group v-model="add_Category.is_video" button-style="solid" @change="Changeis_video">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
           否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否允许跳转">
        <a-radio-group v-model="add_Category.is_jump" button-style="solid" @change="Changeis_jump">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
            否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="跳转链接">
        <a-input v-model="add_Category.jump_url"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Categoryedit" title="编辑分类" width="65%" @ok="Category_editok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="上级分类">
        <!--        {{ treeData }}-->
        <!--        {{ Category_pid }}-->
        <a-tree-select
            v-model="edit_Category.p_id"
            :dropdown-style="{ maxHeight: '600px', overflow: 'auto' }"
            :replaceFields="replaceFields"
            :tree-data="treeData"
            placeholder="Please select"
            allow-clear
            tree-default-expand-all
            style="width: 100%"
            @change="onselectpid"
        >
          <span slot="title" slot-scope="{ key, value }">
            {{ value }}
          </span>
        </a-tree-select>

      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="中文分类名称">
        <a-input v-model="edit_Category.name.cn"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="英文分类名称">
        <a-input v-model="edit_Category.name.en"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="法文分类名称">
        <a-input v-model="edit_Category.name.fr"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="目录排序"  help="默认 -1  最大999">
        <a-input v-model="edit_Category.rank"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="文章封面图">
<!--{{fileList}}-->
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/Categoryimages'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
        <div class="note">
          * 图片尺寸:1920*560<br/>
          * 图片大小不超过2M<br/>
          * 图片类型：jpg或png<br/>
        </div>
      </a-form-item>

      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否显示">
        <a-radio-group v-model="edit_Category.is_show" button-style="solid" @change="Changeis_show">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
            否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否为视频列表">
        <a-radio-group v-model="edit_Category.is_video" button-style="solid" @change="Changeis_video">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
            否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="是否允许跳转">
        <a-radio-group v-model="edit_Category.is_jump" button-style="solid" @change="Changeis_jump">
          <a-radio-button value="1">
            是
          </a-radio-button>
          <a-radio-button value="2">
            否
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="跳转链接">
        <a-input v-model="edit_Category.jump_url"/>
      </a-form-item>
    </a-modal>
    <a-modal v-model="pop_Articlerank" title="编辑文章排序" width="65%" @ok="editArticlerankok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="文章排序"   help="默认 -1  最大999">
        <a-input v-model="Articlerank"/>
      </a-form-item>
    </a-modal>



  </a-layout>

</template>

<script>
// const treeData = [
//   {
//     title: '分类一',
//     key: '0',
//     children: [
//       {
//         title: '分类一一',
//         key: '00',
//         children: [
//           {title: '分类一一一', key: '001'},
//           {title: '分类一一二', key: '002'},
//           {title: '分类一一三', key: '003'},
//         ],
//       },
//       {
//         title: '分类一二',
//         key: '01',
//         children: [
//           {title: '分类一二一', key: '011'},
//           {title: '分类一二二', key: '012'},
//           {title: '分类一二三', key: '013'},
//         ],
//       },
//     ],
//   },
// ];
import moment from "moment";

import Config from "../config";

const columns = [
  {
    title: "id",
    dataIndex: "id",

    scopedSlots: {customRender: "id"}
  },
  {
    title: "排序",
    dataIndex: "rank",
    scopedSlots: {customRender: "rank"}
  },
  {
    title: "语言",
    dataIndex: "language",
    scopedSlots: {customRender: "language"}
  },
  {
    title: "类型",
    dataIndex: "type",
    width: "5%",
    scopedSlots: {customRender: "type"}
  },
  {
    title: "文章名",
    dataIndex: "title",
    scopedSlots: {customRender: "title"}
  },
  {
    title: "作者",
    dataIndex: "author",
    scopedSlots: {customRender: "author"}
  },

  {
    title: "发布时间",
    dataIndex: "created_at",

    scopedSlots: {customRender: "created_at"}
  },


  {
    title: "是否显示",
    width: "7%",
    dataIndex: "is_show",
    scopedSlots: {customRender: "is_show"}
  },
  {
    title: '操作',
    width: "20%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";
// import {quillEditor} from "vue-quill-editor";
// import "quill/dist/quill.snow.css"; //quill编辑器的样式表
// import * as Util from "../common/util";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  // ["blockquote", "code-block"], //引用，代码块
  [{header: 1}, {header: 2}], // 标题，键值对的形式；1、2表示字体大小
  //[{ list: "ordered" }, { list: "bullet" }], //列表
  //[{ script: "sub" }, { script: "super" }], // 上下标
  [{indent: "-1"}, {indent: "+1"}], // 缩进
  [{direction: "rtl"}], // 文本方向
  [{size: ["small", false, "large", "huge"]}], // 字体大小
  [{header: [1, 2, 3, 4, 5, 6, false]}], //几级标题
  [{color: []}, {background: []}], // 字体颜色，字体背景颜色
  [{font: []}], //字体
  [{align: []}], //对齐方式
  ["clean"], //清除字体样式
  // ["image"], //上传图片、上传视频
  // ["image", "video"], //上传图片、上传视频
];
export default {
  name: "Dashboard",
  // components: {quillEditor},
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  data() {
    return {
      columns,
      content_cn: {
        title:"",
        cover_img:"",
        content:"",
        url:"",
        fileList:"",
      }, //文本区域内容
      content_en: {
        title:"",
        cover_img:"",
        content:"",
        url:"",
        fileList:"",
      }, //文本区域内容
      content_fr: {
        title:"",
        cover_img:"",
        content:"",
        url:"",
        fileList:"",
      }, //文本区域内容
      editorOption: {
        modules: {
          toolbar: {
            container: this.toolbarOptions || toolbarOptions, // 自定义工具栏，与上面定义的toolbarOptions 相对应
            handlers: {
              'image': function (value) {
                if (value) {
                  console.log('你点到我了!')
                  // 获取到antd的upload框选择图片的弹出框事件并触发一次点击
                  document.querySelector('.ant-upload .ant-btn').click()
                } else {
                  //禁用quill自带的image时间
                  this.quill.format('image', false)
                }
              }
            } // 事件重写
          }
        },
        theme: "snow",  //主题
        placeholder: "请输入分类信息",
      },
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      data: [],
      treeData: [],
      CategoryarticleData: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      replaceFields: {
        children: 'children',
        title: 'title',
        label: 'title',
        key: 'id',
        value: 'key',
      },
      Category_pid: "",
      Category_id: "",
      Articleinfo: "",
      article_id: "",
      Articlerank: "",
      Category_name: "",
      Category_show: false,
      Category_show2: false,
      loading: false,
      pop_Categoryadd: false,
      pop_Categoryedit: false,
      pop_Articlerank: false,
      pop_Categorychildrenadd: false,
      pop_Articladd: false,
      pop_Articledit: false,
      add_Category: {
        p_id: undefined,
        cover_image: "",
        rank: "",
        is_show: "1",
        is_video: "2",
        is_jump: "2",
        jump_url: "",
        more_info: {
          cn: "",
          en: "",
          fr: "",
        },
        more_info_show: false,
        name: {
          cn: "",
          en: "",
          fr: "",
        },
      },
      edit_Category: {
        p_id: "",
        cover_image: "",
        name: {
          cn: "",
          en: "",
          fr: "",
        },
        more_info: {
          content: "",
        },
        more_info_show: false,
        articles_category_id: "",
        is_show: "",
        is_jump: "",
        jump_url: "",
        rank: "",
      },
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      previewVisible: false,
      fileList: [],
      previewImage: '',
      cover_img: '',
      IntroductionfileListcn: [],
      IntroductionfileListen: [],
      IntroductionfileListfr: [],

    };
  },
  created() {
    // 获取第一页内容
    this.getCategorylist("cn");
    // this.delCategory(25);

    // Promise.all([this.getitem(), this.getresource()]).then(() => {
    //   this.loading = false;
    // }).catch((error) => {
    //   console.log(error)
    // })
  },

  methods: {
    handleChange(info) {
      this.add_Category.cover_image = "";
      this.edit_Category.cover_image = "";
      console.log("info", info);
      console.log(info.file.status);
      this.fileList = info.fileList;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          this.add_Category.cover_image = this.fileList[i].response.src;
          this.edit_Category.cover_image = this.fileList[i].response.src;
          // this.add_article.cover_img = this.fileList[i].response.src;
        }
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.add_Category.cover_image);
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    addArticle(index) {
      console.log("添加", index);
      //主要实现打开新窗口的功能
      const route = this.$router.resolve({
        path: '/articleadd',
        query: {
          category_id: index
        }
      })
      window.open(route.href, '_blank')
    },
    editArticle(index) {
      console.log("编辑", index);
      // console.log("编辑", this.edit_Category);

      window.vue.$router.push({
        path: "/articleedit",
        query: {
          article_id: index.id,
          Category_id: this.Category_id,
        }
      });
    },
    onContextMenuClick(event, e) {
      console.log('event', event);
      console.log('event.length', event.length);
      console.log('e', e);

      if (event.length === 0) {
        this.Category_show = false;
        return;
      }
      if (e.selectedNodes === []) {
        this.Category_show = false;

      } else {
        console.log('length', e.selectedNodes[0].data.props.dataRef);
        this.content_cn= {
          title:"",
          cover_img:"",
          content:"",
          url:"",
          fileList:[],
        }
        this.content_en={
          title:"",
          cover_img:"",
          content:"",
          url:"",
          fileList:[],
        }
        this.content_fr={
          title:"",
          cover_img:"",
          content:"",
          url:"",
          fileList:[],
        }
        console.log('length', this.content_fr.fileList.length);


        this.Category_id = event[0]
        this.Category_name = e.selectedNodes[0].data.props.dataRef.title
        this.Category_show2 = e.selectedNodes[0].data.props.dataRef.is_show
        this.Category_jump = e.selectedNodes[0].data.props.dataRef.is_jump
        console.log('dataRef', e.selectedNodes[0].data.props.dataRef);
        console.log('Category_show2', this.Category_show2);
        console.log('is_jump', this.Category_jump);
        this.Category_pid = e.selectedNodes[0].data.props.dataRef.p_id
        if(e.selectedNodes[0].data.props.dataRef.more_info!==null){
          // this.content_cn = e.selectedNodes[0].data.props.dataRef.more_info.cn
          this.getCategoryinfo(this.Category_id);
          console.log('不为空',this.content_cn)
          // return;
        }else{
          console.log('为空',this.content_cn)

        }
        // if(e.selectedNodes[0].data.props.dataRef.more_info!==null){
        //   this.content_en = e.selectedNodes[0].data.props.dataRef.more_info.en
        // }
        // if(e.selectedNodes[0].data.props.dataRef.more_info!==null){
        //   this.content_fr = e.selectedNodes[0].data.props.dataRef.more_info.fr
        // }



        this.edit_Category.more_info_show = e.selectedNodes[0].data.props.dataRef.more_info_show
        console.log('Category_id', this.Category_id);
        console.log('Category_pid', this.Category_pid);
        console.log('Category_name', this.Category_name);
        this.Category_show = false;
        if (this.Category_pid !== null ) {
        this.getCategoryarticlelist(1, this.pagination.pageSize, this.Category_id)
        this.Category_show = true;
        }
      }
    },
    Changeis_show(e) {
      console.log(e.target.value)
      this.add_Category.is_show = e.target.value
      console.log(this.add_Category.is_show);
    },
    Changeis_video(e) {
      console.log(e.target.value)
      this.add_Category.is_video = e.target.value
      console.log(this.add_Category.is_video);
    },
    Changeis_jump(e) {
      console.log(e.target.value)
      this.add_Category.is_jump = e.target.value
      console.log(this.add_Category.is_jump);
    },
    start() {
      this.loading = true;
      this.getCategoryarticlelist(1, this.pagination.pageSize, this.Category_id);
    },

    async onChange(pagination) {
      console.log(pagination);
      await this.getCategoryarticlelist(
          pagination,
          this.pagination.pageSize,
          this.Category_id
      );
    },

    async onselectpid(value, node) {

      console.log(value);
      this.add_Category.p_id = value;
      console.log(node);

    },
    async onChanges(e) {
      console.log(e.target.value);
    },

    addCategory() {
      console.log("点击");
      this.add_Category.name = {
        cn: "",
        en: "",
        fr: "",
      };
      this.add_Category.p_id = "";
      this.add_Category.type = "1";
      this.add_Category.jump_url = "";
      this.add_Category.cover_image = "";
      this.pop_Categoryadd = true;
    },
    editCategory(e) {
      console.log("点击", e);
      //
      // this.edit_Category.name = this.Category_name;
      // this.edit_Category.p_id = this.Category_pid;
      this.edit_Category.articles_category_id = e;
      if(e){
        this.getCategoryinfo(e);
        this.pop_Categoryedit = true;
      }

    },
    editArticlerank(e) {

      this.pop_Articlerank = true;
      this.Articlerank=e.rank
      this.article_id=e.id
      console.log("点击", this.article_id);
      console.log("点击", this.Articlerank);
    },
    handIntroductionleChangecn(info) {
      console.log("info", info);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.content_cn.fileList=[];
        this.content_cn.cover_img = "";
        return;
      }
      this.content_cn.fileList=info.fileList ;
      for (var i = 0; i < this.content_cn.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.content_cn.cover_img = this.server_url + this.content_cn.fileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.content_cn.cover_img);
    },handIntroductionleChangeen(info) {
      console.log("info", info);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.content_en.fileList=[];
        this.content_en.cover_img = "";
        return;
      }
      this.content_en.fileList=info.fileList ;
      for (var i = 0; i < this.content_en.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.content_en.cover_img = this.server_url + this.content_en.fileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.content_en.cover_img);
    },
    handIntroductionleChangefr(info) {
      console.log("info", info);

      console.log(info.file.status);
      if (info.file.status === "removed") {
        console.log("剔除");
        this.content_fr.fileList=[];
        this.content_fr.cover_img = "";
        return;
      }
      this.content_fr.fileList=info.fileList ;
      for (var i = 0; i < this.content_fr.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          // this.CooperationData[id].cover_img = "";
          this.content_fr.cover_img = this.server_url + this.content_fr.fileList[i].response.src;

        }
        /* eslint-enable */
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.content_fr.cover_img);
    },
    async saveCategoryinfo(e) {
      console.log("点击", e);
      let result = await this.$post("/api/admin/articleCategory/update", {
        uid: Storage.uid,
        token: Storage.token,
        articles_category_id: this.Category_id,
        save_value: {
          // name:this.Category_name,
          more_info:{
            cn:this.content_cn,
            en:this.content_en,
            fr:this.content_fr,
          }
        },
      });
      if (result.status === true) {
        this.data = []
        await this.getCategorylist("cn");
        Modal.info({
          title: "修改成功"
        });
      }
    },
    //打开新窗口并传参，参数不能显示在地址栏里面，不关闭窗口一直刷新，参数一直有效
    onEditorChange_cn({quill, html, text}) {
      console.log('editor_cn change!', quill, html, text)
      this.content_cn = html
      // let  str = text;
      // this.description = str.substr(0,150) + '...';
      // console.log('description',this.description)
    },
    onEditorChange_en({quill, html, text}) {
      console.log('editor_en change!', quill, html, text)
      this.content_en = html
      // let  str = text;
      // this.description = str.substr(0,150) + '...';
      // console.log('description',this.description)
    },
    onEditorChange_fr({quill, html, text}) {
      console.log('editor_fr change!', quill, html, text)
      this.content_fr = html
      // let  str = text;
      // this.description = str.substr(0,150) + '...';
      // console.log('description',this.description)
    },
    handleChangeItem(info) {
      console.log('info', info);
      //这一步是获取quilleditor的实例
      let quill = this.$refs.myQuillEditor.quill

      // 获取光标所在位置
      let length = quill.getSelection().index
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        this.loading = false;
        this.uploadImgUrl =this.server_url+info.file.response.src
        console.log(length)
        // 插入图片  res为服务器返回的图片地址
        quill.insertEmbed(length, 'image', this.uploadImgUrl)
        // 调整光标到最后
        quill.setSelection(length + 1)
        this.$message.success('上传成功')
        //console.log(this.url)
        // this.$message.error('图片插入失败')
      }
    },
    onClose() {
      this.pop_Articladd = false;
      this.pop_Articledit = false;
    },

    async Category_addok() {
      if (this.add_Category.name.cn === "" ||this.add_Category.name.en === ""||this.add_Category.name.fr === "") {
        Modal.warning({
          title: "栏目名"
        });
        this.pop_Categoryadd = true;
        return
      }

      let result = await this.$post("/api/admin/articleCategory/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          p_id: this.add_Category.p_id,
          name: {
            cn: this.add_Category.name.cn,
            en: this.add_Category.name.en,
            fr: this.add_Category.name.fr,
          },
          cover_image: this.add_Category.cover_image,
          is_jump: this.add_Category.is_jump,
          is_show: this.add_Category.is_show,
          is_video: this.add_Category.is_video,
          jump_url: this.add_Category.jump_url,
          rank: this.add_Category.rank,

        },

      });

      if (result.status === true) {
        this.data = []
        await this.getCategorylist("cn");
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Categoryadd = false;
    },


    async getCategorylist(type) {
      this.loading = true;
      let result = await this.$get("/api/admin/articleCategory/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          language: type,
        }
      });

      if (result.status === true) {
        let data = [];

        for (let i in result.data) {
          let info = result.data[i];
          if( info.is_show=== 2 ){
            var text=" [隐藏]";
          }else{
            text="";
          }
          if( info.is_jump=== 1 ){
            var text1=" [跳转]";
          }else{
            text1="";
          }
          if( info.is_video=== 1 ){
            var text11=" [视频列表]";
          }else{
            text11="";
          }
          let data2 = [];
          for (let ii in info.child) {
            let info2 = info.child[ii];
            if( info2.is_show=== 2 ){
              var text2=" [隐藏]";
            }else{
              text2="";
            }
            if( info2.is_jump=== 1 ){
              var text21=" [跳转]";
            }else{
              text21="";
            }
            if( info2.is_video=== 1 ){
              var text22=" [视频列表]";
            }else{
              text22="";
            }
            data2[ii] = {
              label: info2.name.cn,
              title: "["+info2.rank+"] Id:"+info2.id+" "+info2.name.cn+text2+text21+text22,
              key: info2.id,
              id: info2.id,
              value: info2.id,
              p_id: info2.p_id,
              children: info2.child,
              more_info: info2.more_info,
              more_info_show: info2.more_info_show,
              rank: info2.rank,
              // disabled: disabled,
              is_show: info2.is_show,
              is_jump: info2.is_jump,
              childrenname: info2.child,
            };

            // console.log(i, info)

          }
          // console.log(i, info)
          data[i] = {
            label: info.name.cn,
            title: "["+info.rank+"] Id:"+info.id+" "+info.name.cn+text+text1+text11,
            key: info.id,
            id: info.id,
            value: info.id,
            p_id: info.p_id,
            children:data2,
            more_info: info.more_info,
            more_info_show: info.more_info_show,
            rank: info.rank,
            // disabled: disabled,
            is_show: info.is_show,
            is_jump: info.is_jump,
            childrenname: info.child,
          };
        }
        this.treeData = data;
        console.log( this.treeData)
        this.loading = false;
      }

      this.loading = false;
    },

    async getCategoryinfo(id) {
      this.loading = true;
      let result = await this.$get("/api/admin/articleCategory/info", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          articles_category_id: id,
        }
      });

      if (result.status === true) {
        this.fileList=[];
        if(result.data.more_info){
          this.content_cn = result.data.more_info.cn;
          this.content_en = result.data.more_info.en;
          this.content_fr = result.data.more_info.fr;
        }

        this.edit_Category = result.data
        this.edit_Category.is_show = result.data.is_show.toString();
        this.edit_Category.is_jump = result.data.is_jump.toString();
        this.edit_Category.is_video = result.data.is_video.toString();
        console.log("无封面",result.data.cover_image)
        if(result.data.cover_image === null){
          console.log("无封面")

        }else{
          this.fileList=[{
            uid: 0,
            name: 'name',
            status: 'done',
            url: this.server_url + result.data.cover_image,
            response: {
              data: result.data.cover_image
              // , JSON.parse(result.data.more_info_json)[k]
            }
          }]
          this.edit_Category.cover_image = result.data.cover_image
        }

        console.log("edit_Category", this.edit_Category)
        this.loading = false;
      }

      this.loading = false;
    },

    async getCategoryarticlelist(page, limit, articles_category_id) {
      this.loading = true;
      this.pagination.current = page
      this.CategoryarticleData = [];
      let result = await this.$get("/api/admin/article/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          page: page,
          limit: limit,
          articles_category_id: articles_category_id,
          get_all: 2,
          is_show: "",
        }
      });

      if (result.status === true) {
        let data = [];
        console.log(result.data.list)
        for (let i in result.data.list) {
          let info = result.data.list[i];

          data[i] = {
            articles_category_id: info.articles_category_id,
            created_at: moment(info.created_at).format("YYYY/MM/DD hh:mm:ss"),
            author: info.author,
            rank: info.rank,
            cover_image: info.cover_image,
            create_uid: info.create_uid,
            id: info.id,
            introduce: info.introduce,
            is_homework: info.is_homework,
            more_info_json: info.more_info_json,
            title: info.title,
            is_show: info.is_show,
            language: info.language,
            type: info.type,
          };
        }
        this.CategoryarticleData = data;
        let pagination = {...this.pagination};

        pagination.total = result.data.total;
        this.pagination = pagination;
        console.log(this.pagination.current)
        this.loading = false;
      }

      this.loading = false;
    },

    async Category_editok() {
      console.log(this.edit_Category.rank)
      if (this.edit_Category.name.cn === "" ||this.edit_Category.name.en === ""||this.edit_Category.name.fr === "") {
        Modal.warning({
          title: "栏目名不能为空"
        });
        this.pop_Categoryedit = true;
        return
      }


      let result = await this.$post("/api/admin/articleCategory/update", {
        uid: Storage.uid,
        token: Storage.token,
        articles_category_id: this.Category_id,
        save_value: {
          name: {
            cn: this.edit_Category.name.cn,
            en: this.edit_Category.name.en,
            fr: this.edit_Category.name.fr,
          },
          cover_image: this.edit_Category.cover_image,
          jump_url: this.edit_Category.jump_url,
          is_show: this.edit_Category.is_show,
          is_jump: this.edit_Category.is_jump,
          is_video: this.edit_Category.is_video,
          p_id: this.Category_pid,
          rank: this.edit_Category.rank,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.getCategorylist("cn");
        Modal.info({
          title: "修改成功"
        });
      }
      this.Category_name = this.edit_Category.name.cn;
      this.pop_Categoryedit = false;
    },



    async delCategory(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除 id:"+item+" 分类?",

        onOk: async function () {

          let result = await that.$post("/api/admin/articleCategory/delete", {

            uid: Storage.uid,
            token: Storage.token,
            articles_category_id: item,
            // notes: this.edit_pop.notes,
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getCategorylist("cn");
            that.Category_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.getCategoryarticlelist(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
    async deleArticle(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该文章?",

        onOk: async function () {

          let result = await that.$post("/api/admin/article/delete", {

            uid: Storage.uid,
            token: Storage.token,
            article_id: item.id,
            // notes: this.edit_pop.notes,
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getCategoryarticlelist(1, that.pagination.pageSize, that.Category_id)
            // that.Category_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.getCategoryarticlelist(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
    async editArticlerankok(item) {
      console.log("info", item);
      if (this.Articlerank === "") {
        Modal.warning({
          title: "rank不能为空"
        });
        this.Articlerank = true;
        return
      }


      let result = await this.$post("/api/admin/article/sendRank", {
        uid: Storage.uid,
        token: Storage.token,
        article_id: this.article_id,
        rank: this.Articlerank,
      });

      if (result.status === true) {
        await  this.getCategoryarticlelist(1, this.pagination.pageSize, this.Category_id)
        Modal.info({
          title: "修改成功"
        });
      }

      this.pop_Articlerank = false;
    },
  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}

.ant-uploads /deep/ .ant-upload.ant-upload-select-picture-card {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  width: 200px !important;
  height: 200px !important;
}

.ant-uploads /deep/ .ant-upload-list-picture-card-container {
  width: 200px !important;
  height: 200px !important;
}

.ant-tabs {
  overflow: unset !important;
}
.note{
  line-height:110%;
  font-size:12px;
  color: #ccc;
}
</style>
